import flat from 'flat';
import { DELIMITER } from './constants.js';
import { propertyKeyRegex } from './property-key-regex.js';
import { pathToParts } from './path-to-parts.js';
const isObject = value => {
  // Node environment
  if (typeof File === 'undefined') {
    return typeof value === 'object' && value !== null;
  }
  // Window environment
  return typeof value === 'object' && !(value instanceof File) && value !== null;
};

/**
 * @load ./set.doc.md
 * @memberof module:flat
 * @param {FlattenParams} params
 * @param {string} propertyPath
 * @param {any} [value]       if not give function will only try to remove old keys
 * @returns {FlattenParams}
 */
const set = (params = {}, propertyPath, value) => {
  const regex = propertyKeyRegex(propertyPath);

  // remove all existing keys
  const paramsCopy = Object.keys(params).filter(key => !key.match(regex)).reduce((memo, key) => {
    memo[key] = params[key];
    return memo;
  }, {});
  if (typeof value !== 'undefined') {
    if (isObject(value) && !(value instanceof Date)) {
      const flattened = flat.flatten(value);
      if (Object.keys(flattened).length) {
        Object.keys(flattened).forEach(key => {
          paramsCopy[`${propertyPath}${DELIMITER}${key}`] = flattened[key];
        });
      } else if (Array.isArray(value)) {
        paramsCopy[propertyPath] = [];
      } else {
        paramsCopy[propertyPath] = {};
      }
    } else {
      paramsCopy[propertyPath] = value;
    }

    // when user gave { "nested.value": "something" } and had "nested" set to `null`, then
    // nested should be removed
    const parts = pathToParts(propertyPath).slice(0, -1);
    if (parts.length) {
      return Object.keys(paramsCopy).filter(key => !parts.includes(key)).reduce((memo, key) => {
        memo[key] = paramsCopy[key];
        return memo;
      }, {});
    }
  }
  return paramsCopy;
};
export { set };