import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useLocalStorage } from '../hooks/use-local-storage/index.js';
import { changeRoute, initializeRoute } from '../store/actions/route-changed.js';
const useHistoryListen = () => {
  const location = useLocation();
  const [storedPath, setStoredPath] = useLocalStorage('prevPage', {});
  const {
    to = {},
    from = {}
  } = useSelector(state => state.router);
  const dispatch = useDispatch();
  useEffect(() => {
    if (storedPath) {
      dispatch(initializeRoute(storedPath));
    }
  }, []);
  useEffect(() => {
    const previousPath = [to.pathname, to.search].join('');
    const currentPath = [location.pathname, location.search].join('');
    if (previousPath !== currentPath) {
      dispatch(changeRoute(location));
    }
  }, [location]);
  useEffect(() => {
    if (from.pathname) {
      setStoredPath(from);
    }
  }, [from]);
};
export { useHistoryListen, useHistoryListen as default };